/**
 * This script adds accessibility-ready responsive menus to Genesis Framework child themes.
 * 
 * @author StudioPress
 * @link https://github.com/copyblogger/responsive-menus
 * @version 1.1.3
 * @license GPL-2.0+
 */

(function(document, $, undefined) {
    'use strict';

    const genesisMenuParams = typeof genesis_responsive_menu === 'undefined' ? '' : genesis_responsive_menu;
    const genesisMenusUnchecked = genesisMenuParams.menuClasses;
    const genesisMenus = {};
    let menusToCombine = [];

    /**
     * Validate the menus passed by the theme with what's being loaded on the page,
     * and pass the new and accurate information to our new data.
     * @param {Array} genesisMenusUnchecked Raw data from the localized script in the theme.
     * @return {Array} genesisMenus array gets populated with updated data.
     * @return {Array} menusToCombine array gets populated with relevant data.
     */
    $.each(genesisMenusUnchecked, function(group) {
        genesisMenus[group] = [];

        $.each(this, function(key, value) {
            const menuString = value;
            const $menu = $(value);

            if ($menu.length > 1) {
                $.each($menu, function(key, value) {
                    const newString = menuString + '-' + key;
                    $(this).addClass(newString.replace('.', ''));
                    genesisMenus[group].push(newString);

                    if (group === 'combine') {
                        menusToCombine.push(newString);
                    }
                });
            } else if ($menu.length === 1) {
                genesisMenus[group].push(menuString);

                if (group === 'combine') {
                    menusToCombine.push(menuString);
                }
            }
        });
    });

    if (typeof genesisMenus.others === 'undefined') {
        genesisMenus.others = [];
    }

    if (menusToCombine.length === 1) {
        genesisMenus.others.push(menusToCombine[0]);
        genesisMenus.combine = null;
        menusToCombine = null;
    }

    const genesisMenu = {};
    const mainMenuButtonClass = 'menu-toggle';
    const subMenuButtonClass = 'sub-menu-toggle';
    const responsiveMenuClass = 'genesis-responsive-menu';

    


	function _initMenuItemHasChildren() {
        if ($(window).width() <= 1024) {
            $(document).on('click', '.menu-item-has-children > a', function(event) {
                event.preventDefault();
                const $this = $(this);
                const $submenu = $this.siblings('.sub-menu');

                // Toggle the display of the clicked submenu
                $submenu.slideToggle('fast');

                // Hide all other submenus at the same level
                $this.parent().siblings('.menu-item-has-children').find('> .sub-menu').slideUp('fast');
            });

            // Nested sub-menu items handling
            // $(document).on('click', '.menu-item-has-children .menu-item-has-children > a', function(event) {
            //     event.preventDefault();
            //     const $this = $(this);
            //     const $submenu = $this.siblings('.sub-menu');

            //     // Toggle the display of the clicked submenu
            //     $submenu.slideToggle('fast');

            //     // Hide all other submenus at the same level
            //     $this.parent().siblings('.menu-item-has-children').find('> .sub-menu').slideUp('fast');
            // });
        } else {
            // Remove previously attached click events to avoid duplication
            $(document).off('click', '.menu-item-has-children > a');
            $(document).off('click', '.menu-item-has-children .menu-item-has-children > a');
        }
	}

    genesisMenu.init = function() {
        if ($(_getAllMenusArray()).length === 0) {
            return;
        }

        const menuIconClass = genesisMenuParams.menuIconClass || '';
        const subMenuIconClass = genesisMenuParams.subMenuIconClass || '';

        const toggleButtons = {
            menu: $('<button />', {
                class: mainMenuButtonClass,
                'aria-expanded': false,
                'aria-pressed': false,
            })
                .append(genesisMenuParams.mainMenu)
                .append($('<span />')),
            submenu: $('<button />', {
                class: subMenuButtonClass,
                'aria-expanded': false,
                'aria-pressed': false,
            }).append($('<span />', {
                class: 'screen-reader-text',
                text: genesisMenuParams.subMenu,
            })),
        };

        function checkWindowWidthAndRun() {
            if (window.matchMedia("(max-width: 1024px)").matches) {
                _addResponsiveMenuClass();
                _addMenuButtons(toggleButtons);
            } else {
                comsole.log('does not match');
            }
        }

        checkWindowWidthAndRun();
        $(window).resize(checkWindowWidthAndRun);

        const mainMenuButton = $('.' + mainMenuButtonClass);
        const subMenuButton = $('.' + subMenuButtonClass);

        mainMenuButton.addClass(menuIconClass);
        subMenuButton.addClass(subMenuIconClass);

        const searchIcons = $('.search-form-bar .get-search-form i');
        searchIcons.on('click.genesisMenu-mainbutton', _clickToggle);
        
        mainMenuButton.on('click.genesisMenu-mainbutton', _mainmenuToggle).each(_addClassID);
        subMenuButton.on('click.genesisMenu-subbutton', _submenuToggle);

        $(window).on('resize.genesisMenu', _doResize).triggerHandler('resize.genesisMenu');

		_initMenuItemHasChildren();
    };

    function _addMenuButtons(toggleButtons) {
        $(_getMenuSelectorString(genesisMenus)).find('.sub-menu').before(toggleButtons.submenu);
        $(_getMenuSelectorString(genesisMenus.others)).before(toggleButtons.menu);

        // if (menusToCombine !== null) {
        //     const menusToToggle = genesisMenus.others.concat(menusToCombine);
            
        //     $(_getMenuSelectorString(menusToToggle)).before(toggleButtons.menu);
        // } else {
        //     $(_getMenuSelectorString(genesisMenus.others)).before(toggleButtons.menu);
        // }
    }

    function _addResponsiveMenuClass() {
        $(_getMenuSelectorString(genesisMenus)).addClass(responsiveMenuClass);
    }

    function _doResize() {
        const buttons = $('button[id^="genesis-mobile-"]').attr('id');
        if (typeof buttons === 'undefined') {
            return;
        }
        _maybeClose(buttons);
        _superfishToggle(buttons);
        _changeSkipLink(buttons);
        _combineMenus(buttons);
    }

    function _addClassID() {
        const $this = $(this);
        const nav = $this.next('nav');
        const id = 'class';
        $this.attr('id', 'genesis-mobile-' + $(nav).attr(id).match(/nav-\w*\b/));
    }

    function _combineMenus(buttons) {
        if (menusToCombine === null) {
            return false;
        }

        const primaryMenu = menusToCombine[0];
        const combinedMenus = $(menusToCombine).filter((index) => index > 0);

        if (_getDisplayValue(buttons) !== 'none') {
            $.each(combinedMenus, function(key, value) {
                $(value).find('.menu > li').addClass('moved-item-' + value.replace('.', '')).appendTo(primaryMenu + ' ul.genesis-nav-menu');
            });
            $(_getMenuSelectorString(combinedMenus)).hide();
        } else {
            $(_getMenuSelectorString(combinedMenus)).show();
            $.each(combinedMenus, function(key, value) {
                $('.moved-item-' + value.replace('.', '')).appendTo(value + ' ul.genesis-nav-menu').removeClass('moved-item-' + value.replace('.', ''));
            });
        }
    }

    function closeMenuOnItem($navigation) {
        $($navigation).find('> li').on('click', (e) => {
            const bodyElement = $('body');
            const navPrimary = $('.nav-primary');
            const mobileNavPrimary = $('#genesis-mobile-nav-primary');

            if ($(e.target.parentNode.hash).length > 0) {
                bodyElement.removeClass('nav-open');
                mobileNavPrimary.removeClass('activated');
                navPrimary.slideUp('fast');
                return false;
            }
            if ((bodyElement.hasClass('nav-open') && $(e.target).hasClass('sub-menu-toggle')) || e.target.tagName === 'INPUT') {
                return false;
            }
            if (!$(e.target).hasClass('search-toggle')) {
                mobileNavPrimary.removeClass('activated');
                navPrimary.slideUp('fast');
            }
        });
    }

    function _mainmenuToggle() {
        const $this = $(this);
        const header = $('.site-header');
        const navigation = $this.next('nav');
        _toggleAria($this, 'aria-pressed');
        _toggleAria($this, 'aria-expanded');
        $this.toggleClass('activated');
        $('body').toggleClass('nav-open');
        navigation.css({ marginTop: header.outerHeight() });
        navigation.slideToggle('fast');
    }

    function _clickToggle(){
        const header = $('.site-header');
        const navigation = $('nav');
        _toggleAria(navigation, 'aria-pressed');
        _toggleAria(navigation, 'aria-expanded');
        _toggleAria( $('.menu-toggle'), 'aria-pressed');
        _toggleAria( $('.menu-toggle'), 'aria-expanded');
        navigation.toggleClass('activated');
        $('.menu-toggle').toggleClass('activated');
        $('body').toggleClass('nav-open');
        navigation.css({ marginTop: header.outerHeight() });
        navigation.slideToggle('fast');

        $('#searchform input').focus();
    }

    function _submenuToggle() {
        const $this = $(this);
        const others = $this.closest('.menu-item').siblings();
        _toggleAria($this, 'aria-pressed');
        _toggleAria($this, 'aria-expanded');
        $this.toggleClass('activated');
        $this.parent('li').toggleClass('item-open');
        $this.next('.sub-menu').slideToggle('fast');
        others.find('.' + subMenuButtonClass).removeClass('activated').attr('aria-pressed', 'false');
        others.find('.sub-menu').slideUp('fast');
    }

    function _superfishToggle(buttons) {
        const _superfish = $('.' + responsiveMenuClass + ' .js-superfish');
        let $args = 'destroy';
        if (typeof _superfish.superfish !== 'function') {
            return false;
        }
        if (_getDisplayValue(buttons) === 'none') {
            $args = {
                delay: 100,
                animation: { opacity: 'show', height: 'show' },
                dropShadows: false,
                speed: 'fast',
            };
        }
        _superfish.superfish($args);
    }

    function _changeSkipLink(buttons) {
        const menuToggleList = _getAllMenusArray();

        if (!$(menuToggleList).length > 0) {
            return false;
        }

        $.each(menuToggleList, function(key, value) {
            const newValue = value.replace('.', '');
            let startLink = 'genesis-' + newValue;
            let endLink = 'genesis-mobile-' + newValue;

            if (_getDisplayValue(buttons) === 'none') {
                startLink = 'genesis-mobile-' + newValue;
                endLink = 'genesis-' + newValue;
            }

            const $item = $('.genesis-skip-link a[href="#' + startLink + '"]');

            if (menusToCombine !== null && value !== menusToCombine[0]) {
                $item.toggleClass('skip-link-hidden');
            }

            if ($item.length > 0) {
                let link = $item.attr('href');
                link = link.replace(startLink, endLink);
                $item.attr('href', link);
            }
        });
    }

	

    function _maybeClose(buttons) {
        if (_getDisplayValue(buttons) !== 'none') {
            return true;
        }

        $('.' + mainMenuButtonClass + ', .' + responsiveMenuClass + ' .sub-menu-toggle')
            .removeClass('activated')
            .attr('aria-expanded', false)
            .attr('aria-pressed', false);

        $('.' + responsiveMenuClass + ', .' + responsiveMenuClass + ' .sub-menu').attr('style', '');
    }

    function _getDisplayValue($id) {
        const element = document.getElementById($id);
        const style = window.getComputedStyle(element);
        return style.getPropertyValue('display');
    }

    function _toggleAria($this, attribute) {
        $this.attr(attribute, function(index, value) {
            return value === 'false';
        });
    }

    function _getMenuSelectorString(itemArray) {
        const itemString = $.map(itemArray, function(value) {
            return value;
        });

        return itemString.join(',');
    }

    function _getAllMenusArray() {
        const menuList = [];

        if (menusToCombine !== null) {
            $.each(menusToCombine, function(key, value) {
                menuList.push(value.valueOf());
            });
        }

        $.each(genesisMenus.others, function(key, value) {
            menuList.push(value.valueOf());
        });

        if (menuList.length > 0) {
            return menuList;
        }
    }

    $(document).ready(function() {
        if (_getAllMenusArray() !== null) {
            genesisMenu.init();
        }
    });


    

})(document, jQuery);
