document.addEventListener('DOMContentLoaded', function() {
    let canLoadMore = true;

    window.addEventListener('scroll', function() {
        if (canLoadMore && window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) {
            let currentPage = parseInt(myAjax.current_page);
            let maxPage = parseInt(myAjax.max_page);

            if (currentPage < maxPage) {
                canLoadMore = false;
                let nextPage = currentPage + 1;

                let data = {
                    action: 'load_more_posts',
                    page: nextPage,
                    query: myAjax.query_vars
                };

                let xhr = new XMLHttpRequest();
                xhr.open('POST', myAjax.ajaxurl, true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

                xhr.onload = function() {
                    if (xhr.status >= 200 && xhr.status < 400) {
                        let response = JSON.parse(xhr.responseText);
                        if (response.success) {
                            document.getElementById('post-wrapper').insertAdjacentHTML('beforeend', response.data);
                            myAjax.current_page = nextPage; // Update current page
                            canLoadMore = true; // Reset flag
                        }
                    } else {
                        console.error('Error:', xhr.status, xhr.statusText);
                    }
                };


                let params = new URLSearchParams(data).toString();
                xhr.send(params);
            }
        }
    });
});
