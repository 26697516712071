document.addEventListener('DOMContentLoaded', function() {
  const fadeUpElements = document.querySelectorAll('.fade__up');
  const fadeRightElements = document.querySelectorAll('.fade__right');
  const fadeLeftElements = document.querySelectorAll('.fade__left');
 
  function handleScroll() {
    fadeUpElements.forEach((element, index) => {
      if (isElementInViewport(element)) {
        element.style.transitionDelay = `${index * 0.01}s`; 
        element.classList.add('visible');
      }
    });

    fadeRightElements.forEach((element, index) => {
      if (isElementInViewport(element)) {
        element.style.transitionDelay = `${index * 0.04}s`;
        element.classList.add('visible');
      }
    });

    fadeLeftElements.forEach((element, index) => {
      if (isElementInViewport(element)) {
        element.style.transitionDelay = `${index * 0.04}s`;
        element.classList.add('visible');
      }
    });
  }

  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top < window.innerHeight && 
      rect.bottom > 0 &&
      rect.left < window.innerWidth && 
      rect.right > 0
    );
  }

  let scrollTimeout;
  function optimizedScroll() {
    if (!scrollTimeout) {
      scrollTimeout = setTimeout(() => {
        requestAnimationFrame(handleScroll);
        scrollTimeout = null;
      }, 100); // Adjust the debounce timing as needed
    }
  }

  window.addEventListener('scroll', optimizedScroll);
  window.addEventListener('resize', handleScroll);

  // Trigger scroll event on page load
  handleScroll();
});